<template>
  <div class="header position-relative">
    <!-- Header nav only for small-screen devices -->
    <MobileHeaderNav />

    <!-- Image and description for any device -->
    <div class="d-lg-flex flex-column">
      <div
        class="
          py-2
          px-2
          header-text
          fs-5
          d-none d-lg-block
          text-center
          bg-success
          text-white
        "
      >
        {{ $t("header.description") }}
      </div>

      <div v-if="id">
        <router-link to="/">
          <div class="background-wrapper">
            <img
              alt="header"
              :src="require(`@/assets/header/subtop/explore-${id}.jpg`)"
              class="w-100 d-none d-lg-block"
            />
            <img
              alt="header"
              :src="require(`@/assets/header/subtop/num/${id}.png`)"
              class="d-none d-lg-block subtop-num"
            />
          </div>
        </router-link>
        <div class="m-background-wrapper">
          <img
            alt="header"
            :src="require(`@/assets/header/m-subtop/background/${id}.png`)"
            class="w-100 d-lg-none m-subtop-background"
          />
          <img
            alt="header"
            :src="require(`@/assets/header/m-subtop/num/${id}.png`)"
            class="d-lg-none m-subtop-num"
          />
        </div>
      </div>

      <div v-if="area">
        <router-link to="/">
          <div class="background-wrapper">
            <img
              alt="header"
              :src="
                require(`@/assets/header/subtop/explore-${area}.jpg`)
              "
              class="w-100 d-none d-lg-block"
            />
            <img
              alt="header"
              :src="require(`@/assets/header/subtop/num/${area}.png`)"
              class="d-none d-lg-block subtop-num"
            />
          </div>
          <div class="m-background-wrapper">
            <img
              alt="header"
              :src="require(`@/assets/header/m-subtop/background/${area}.png`)"
              class="w-100 w-100 d-lg-none"
            />
            <img
              alt="header"
              :src="require(`@/assets/header/m-subtop/num/${area}.png`)"
              class="d-lg-none m-subtop-num"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MobileHeaderNav from "./components/MobileHeaderNav.vue";
export default {
  components: { MobileHeaderNav },
  props: ["area"],
  data() {
    return { id: this.$route.params.area_id };
  },
  // mounted() {
  //   console.log(this.area);
  // },
};
</script>

<style lang="scss">
.m-background-wrapper {
  max-height: 226px;
  display: grid;
  grid-template-areas: "num";
  img {
    grid-area: num;
  }
  .m-subtop-num {
    position: relative;
    top: 16.5%;
    left: 2.5%;
  }
}
.background-wrapper {
  display: grid;
  grid-template-areas: "num";
  img {
    grid-area: num;
  }
  .subtop-num {
    position: relative;
    top: 22%;
    left: 2%;
  }
}

@media screen and (max-width: 1200px) {
  .subtop-num {
    position: relative;
    top: 20% !important;
    left: 1.5% !important;
    width: 300px;
    height: 90px;
  }
}

@media screen and (min-width: 1200px) {
  .subtop-num {
    position: relative;
    top: 22%;
    left: 2%;
    width: 350px;
    height: 102px;
  }
}

@media screen and (max-width: 769px) {
  .m-subtop-num {
    position: relative;
    top: 24% !important;
    left: 4% !important;
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 550px) {
  .m-subtop-num {
    position: relative;
    top: 24% !important;
    left: 5% !important;
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 415px) {
  .m-subtop-num {
    position: relative;
    top: 22% !important;
    left: 3.5% !important;
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 361px) {
  .m-subtop-num {
    position: relative;
    top: 22% !important;
    left: 4% !important;
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 321px) {
  .m-subtop-num {
    position: relative;
    top: 21% !important;
    left: 3.5% !important;
    width: 60px;
    height: 60px;
  }
}

.header {
  --bs-gutter-x: 0;
  padding: 0 0 20px 0;
}
</style>
