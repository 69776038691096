<template>
  <div>
    <div class="container-fluid px-0">
      <div class="col-12 p-0">
        <Header :area="area"></Header>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9">
          <slot></slot>
        </div>

        <div class="col-12 col-lg-3 d-none d-lg-block">
          <Sidebar></Sidebar>
        </div>
      </div>
    </div>
    <div class="container-fluid px-0 footer__wrapper">
      <div class="col-12 p-0">
        <Footer></Footer>
      </div>
      <footer>
        <p class="text-white bg-success text-center small p-2 m-0">
          Copyright ©2021 What's Up Danang. All Rights Reserved.
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import Header from "../../layout/header/DetailHeader.vue";
import Footer from "../../layout/footer/Footer.vue";
import Sidebar from "../sidebar/Sidebar.vue";
export default {
  name: "LargeLayout",
  components: { Header, Footer, Sidebar },
  props: ["area"],
};
</script>

<style lang="scss" scoped>
.footer__wrapper {
  background-position: bottom right;
  background: url("../../assets/footer/m_footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 2px solid var(--success);
}
@media screen and (min-width: 992px) {
  .footer-wrapper {
    font-size: 11px;
    background: url("../../assets/footer/footer.jpg");
    border-top: 2px solid var(--success);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
@media screen and (max-width: 992px) {
  .col-12 {
    padding: 0 8px;
  }
}
</style>
